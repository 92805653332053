import React, { useState, useEffect } from "react";

// Toast Component
// Props:
// - message: string (required) - The message to display
// - bgColor: string (optional) - Background color (default: '#4CAF50')
// - duration: number (optional) - How long to display in ms (default: 3000)
// - onClose: function (optional) - Callback when toast closes
const Toast = ({ message, bgColor = "#4CAF50", duration, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        setVisible(false);
        if (onClose) onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  if (!visible) return null;

  const toastStyle = {
    position: "fixed",
    top: "20px",
    right: "20px",
    backgroundColor: bgColor,
    color: "white",
    padding: "10px 15px",
    borderRadius: "4px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    zIndex: 1000,
    maxWidth: "300px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const closeButtonStyle = {
    background: "transparent",
    border: "none",
    color: "white",
    marginLeft: "10px",
    cursor: "pointer",
    fontSize: "16px",
  };

  return (
    <div style={toastStyle}>
      <span>{message}</span>
      <button
        style={closeButtonStyle}
        onClick={() => {
          setVisible(false);
          if (onClose) onClose();
        }}
      >
        ✕
      </button>
    </div>
  );
};

export default Toast;
